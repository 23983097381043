import React from 'react';

import ContentWrapper from '../components/contentWrapper';
import Layout from '../components/layout';
import Footer from '../components/footer';
import SubscribeForm from '../components/subscribeForm';
import SEO from '../components/seo';

const IndexPage = () => (
  <>
    <Layout>
      <SEO title="Home" />
      <ContentWrapper>
        <h2>
          Celebrating customer experiences worth loving with stories worth
          sharing.
        </h2>
        <p>No trashing. No teardowns. Just the good stuff.</p>
      </ContentWrapper>
    </Layout>
    <Footer />
  </>
);

export default IndexPage;
