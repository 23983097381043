import React from "react"

import ContentWrapper from './contentWrapper';
import stylesheet from "./footer.module.css"

const Footer = () => {

  return (
      <footer>
        <ContentWrapper>
          <div className={stylesheet.colophon}>
            <p>
            Made at home in Marietta, Georgia. {new Date().getFullYear()}
            </p>
          </div>
        </ContentWrapper>
      </footer>
  )
}

export default Footer
